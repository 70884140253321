Search = function() { Model.call(this); };

Search.prototype             = new Model();
Search.prototype.constructor = Search;

Search.prototype.applications = function(part_type_code, model_id, year, callbacks)
{
    this.ajax({
        url:       'search/applications',
        type:      'GET',
        dataType:  'json',
        callbacks:  callbacks,

        data: {
            part_type_code: part_type_code,
            model_id:       model_id,
            year:           year
        }
    });
};

Search.prototype.stocks = function(application_id, callbacks)
{
	var application_clicked = '';
	if(typeof(application_clicked_ids) == 'undefined'){
		var application_clicked_ids = [];
	}
	
	if($.inArray(application_id, application_clicked_ids) == '-1'){
		application_clicked = 1;
	}else{
		application_clicked = 0;
	}
	application_clicked_ids.push(application_id);
		
    this.ajax({
        url:       'search/stocks/'+application_id + '/' + application_clicked + '/' + Math.floor((Math.random()*1000)+1),
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

Search.prototype.stocks_aftermarket = function(model_id, year, part_type_code, callbacks)
{
    this.ajax({
        url:       'search/stocks_aftermarket/' + Math.floor((Math.random()*1000)+1),
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks,
        data: {
            model_id: model_id,
            year: year,
            part_type_code: part_type_code
        }
    });
};

Search.prototype.all_stocks = function(application_id, callbacks)
{
    this.ajax({
        url:       'search/all_stocks/'+application_id,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

Search.prototype.part_types = function(model_id, year, callbacks)
{
    this.ajax({
        url:       'search/part_types',
        type:      'GET',
        dataType:  'json',
        async:     false,
        callbacks: callbacks,
        data: {
            model_id: model_id,
            year: year
        }
    });
};

Search.prototype.makes = function(year, callbacks)
{
    this.ajax({
        url:       'search/makes/'+year,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

Search.prototype.models = function(year, make_id, callbacks)
{
    this.ajax({
        url:       'search/models/'+year+'/'+make_id,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};

Search.prototype.get_missing_composites = function(stock_id, callbacks)
{
    this.ajax({
        url:       'search/missing_composites/'+stock_id,
        type:      'GET',
        dataType:  'json',
        callbacks: callbacks
    });
};
